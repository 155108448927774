import React from "react";
import DefaultLayout from "../layouts/default";
import FluidImage from "../components/FluidImage.js";
import Breadcrumbs from "../components/Custom/Navigation/Breadcrumbs";
import { Link } from "gatsby";

export default ({ location }) => {
	//  const dispatch = useDispatch();

	//   React.useEffect(() => {

	//   });

	return (
		<DefaultLayout location={location}>
			
      	<Breadcrumbs location={location} />

			{/* on this page */}
      		<div className="container is-fullwidth">
				<section className="section section-mini">
					<p className="is-normal">
						On this page:{" "}
						<a href="#1" className="">
							Partnership
						</a>
						,{" "}
						<a href="#2" className="">
							Become a partner
						</a>
						{/* ,{" "}
						<a href="#3" className="">
							Active partners
						</a> */}
					</p>
				</section>
			</div>

			{/* partnership */}
      		<a id="1" className="scroll-anchor"></a>
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>Better together – we are partners.</h1>
              				<p>
								&mdash; To make Network Ventures even more successful members are joined by partners. Partners are the cherry on top of Network Ventures.
							</p>
						</div>
					</div>

					<div className="columns is-tablet mt-25">
						<div className="column">
							<p className="is-normal">
								Partners are our direct link into the real business world. All of us want to make rockets fly (and have great barbecues) and our partners from all fields of business are our navigators and tell us which planets we should head for.
							</p>
						</div>
						<div className="column">
							<p className="is-normal">
							With partners knowing exactly what the market and the target group needs they are an essential part of the cooperative. On the opposite partners get early access to new innovative ideas and get a competitive advantage in their respective market.
							</p>
						</div>
					</div>

					<div className="columns is-tablet is-centered">
						<div className="column is-8 mt-50">
						<nav className="breadcrumb breadcrumb-forward is-small is-letter-spacing is-uppercase">
							<ul>
							<li>
								<Link to={"/codeofconduct"}>
								Code of conduct
								<span className="icon icon-arrow-right"></span>
								</Link>
							</li>
							</ul>
						</nav>
						</div>
					</div>

				</section>
			</div>

			{/* become a partner */}
      		<a id="2" className="scroll-anchor"></a>
			<div className="hero hero-cta is-primary mt-50">
				<div className="hero-body">
					<div className="container is-layout">
						<section className="section">
							<div className="columns is-tablet is-centered">
								<div className="column is-8">
									<h1>Cool, I maybe want to be your partner.</h1>

									<p className="mb-50">
										That's great news. Let's get together and sort everything out.
									</p>
									<a
										href="mailto:hi@ntwk.ventures?subject=Partner of Network Ventures"
										className="button is-primary is-inverted is-letter-spacing is-uppercase mb-50"
									>
										Contact us
									</a>
									<p>Is this the beginning of a beautiful friendship?</p>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>

			{/* active partners */}
      		{/* <a id="3" className="scroll-anchor"></a>
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>Dear partners, thank you for making this spaceship fly.</h1>
						</div>
					</div>

					<div className="table-container">
						<table className="table is-hoverable is-fullwidth">
							<thead>
								<tr>
									<th>Name</th>
									<th>Partnership</th>
									<th>Field</th>
									<th>Company</th>
									<th>Contact</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="title">Some Body</td>
									<td>Patronage</td>
									<td>Field</td>
									<td>Company</td>
									<td>
										<a href="http://partner.com">Website</a>,{" "}
										<a href="mailto:some.body@partner.com">Email</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<p className="is-normal">... who's missing from this list? <a href="#2">You?</a></p>
				</section>
			</div> */}
		
    	</DefaultLayout>
	);
};
